import { notification } from 'antd';
import { API_HOST } from './constants';
import { APPROVED } from '../types';

export const currency = (amount) =>
  Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' })
    .format(isNaN(amount) ? 0 : amount)
    .replace(/\D00$/, '');

export const splitCamelCase = (s) => s.replace(/([a-z](?=[A-Z]))/g, '$1 ');

export const capitalize = (s) => s[0].toUpperCase() + s.slice(1);

export const getApplicantFullName = (applicant) => {
  if (!applicant) return '';
  const { firstName, middleName, lastName } = applicant;
  return [firstName, middleName, lastName].filter((v) => v).join(' ');
};

export const openNotification = (message, description, isError) => {
  const fn = isError ? 'error' : 'success';

  notification[fn]({
    message,
    description,
    placement: 'bottom'
  });
};

export const diffArray = (arr1, arr2) => {
  return arr1.filter((x) => !arr2.includes(x));
};

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      // eslint-disable-next-line func-names
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getFileAccessUrl = (key) => `${API_HOST}/files?key=${key}`;

export const getShouldEnablePreSettlement = (decidedAt, outcomeOfApplication) =>
  decidedAt && outcomeOfApplication == APPROVED;
